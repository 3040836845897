<template>
  <div class="culture">
    <div class="box">
      <div class="cn_title">非遗文化展示</div>
      <div class="en_title">Intangible Cultural Heritages</div>
      <div class="line"></div>
      <div v-for="(item, index) in newsList" :key="index" class="news">
        <div v-if="index % 2 === 1" class="dub_box">
          <div class="left_box">
            <img class="left_img" src="@/assets/img/frame_left.png" />
            <img class="right_img" src="@/assets/img/frame_right.png" />
            <img @click="goDetail(item.id)" :src="item.cover" class="image"/>
          </div>
          <div class="right_box">
            <!-- <div class="title">{{item.title}}</div> -->
            <el-tooltip class="item" effect="dark" :content="item.title" placement="top-start">
              <div @click="goDetail(item.id)" class="title">{{item.title}}</div>
            </el-tooltip>
            <div style="display:flex;margin: 0.5rem 0" class="city"><img src="@/assets/img/icon_location.png" style="height:14px;margin-right: 10px;margin-top: 2px;"/>{{item.city}}</div>
            <div @click="goDetail(item.id)" class="content">{{item.describes}}</div>
            <div>
              <span class="btn" @click="goDetail(item.id)">阅读全文</span>
            </div>
          </div>
        </div>
        <div v-else class="odd_box">
          <div class="left_box">
            <!-- <div class="title">{{item.title}}</div> -->
            <el-tooltip class="item" effect="dark" :content="item.title" placement="top-start">
              <div @click="goDetail(item.id)" class="title">{{item.title}}</div>
            </el-tooltip>
            <div class="city"><img src="@/assets/img/icon_location.png" style="height:14px;margin-right: 10px;margin-top: 2px;"/>{{item.city}}</div>
            <div @click="goDetail(item.id)" class="content">{{item.describes}}</div>
            <div>
            <div class="btn" @click="goDetail(item.id)">阅读全文</div>
            </div>
          </div>
          <div class="right_box">
            <img style="position: absolute;bottom:0.2rem;left:0.2rem;width:3rem;height:3rem" src="@/assets/img/frame_left.png" />
            <img style="position: absolute;right:0.2rem;top:0.2rem;width:3rem;height:3rem" src="@/assets/img/frame_right.png" />
            <img @click="goDetail(item.id)" :src="item.cover" class="image"/>
          </div>
        </div>
        <div v-show="index !== newsList.length -1" class="color_line">
          <img style="width:100%;height:100%" src="@/assets/img/line.png" />
        </div>
      </div>
      <div class="block" style="margin-top: 1.6rem">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          background
          :page-size="limit"
          layout="prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <el-backtop style="width:1rem" :right="10" :bottom="50">
      <div style="{height: 100%;width: 100%;}">
        <img style="width:100%;height:100%" src="@/assets/img/toTop.png" />
        <div style="text-align:center;font-size:14px" class="goTop">回顶部</div>
      </div>
    </el-backtop>
  </div>
</template>

<script>

export default {
  name: 'culture',
  data() {
    return {
      currentPage: 1,
      categoryId: 0,
      page: 1,
      limit: 10,
      total: 0,
      newsList: []
    }
  },
  methods: {
    // 获取栏目列表
    getCategory() {
      const url = '/api/web_index/feature?categoryId=15' + '&page=' + this.page + '&limit=' + this.limit
      this.$axios.get(url).then(res => {
        console.log('46--->', res.data)
        const a = res.data
        // this.title = a.categoryName
        // this.intro = a.describes
        this.newsList = a.documentsPage.records
        this.total = a.documentsPage.total
        document.body.scrollTop = document.documentElement.scrollTop = 750
      })
    },
    // 跳转至详情页
    goDetail(val) {
      this.$router.push({ path: '/detail', query: { id: val } })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.page = val
      this.getCategory()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page = val
      this.getCategory()
    }
  },
  mounted() {
    this.categoryId = this.$route.query.categoryId
    this.getCategory()
  }
}
</script>

<style lang="scss" scoped>
@import 'index.scss';
.culture {
  .el-backtop {
    background-color: transparent;
    box-shadow: none;
  }
}
.box {
  // margin-left: 11.8%;
  // margin-right: 11.8%;
  background: #f4f4f4;
  border-radius: 30px;
  padding-top: 2rem;
  padding-bottom: 2.4rem;
  .city {
    height: 14px;
    font-size: 14px;
    font-family: 'PingFangSC', 'PingFangSC-Thin';
    font-weight: 200;
    color: #333333;
    display:flex;
    margin: 0.5rem 0
  }
  .cn_title {
    text-align: center;
    font-size: 0.8rem;
    font-family: 'MicrosoftYaHei', 'MicrosoftYaHei-Bold';
    font-weight: 700;
    color: #333333;
    line-height: 0.8rem;
    height: 0.8rem;
    letter-spacing: 2px;
  }
  .en_title {
    text-align: center;
    margin-top: 0.6rem;
    height: 0.4rem;
    opacity: 1;
    font-size: 0.4rem;
    font-family: 'SimHei';
    color: #666666;
    line-height: 0.4rem;
    letter-spacing: 2px;
  }
  .line {
    // width: 80%;
    height: 1px;
    background-color: #e6e6e6;
    // margin-left: 10%;
    margin-top: 1.6rem;
  }
  .news {
    padding: 0.3rem 0.2rem 0 0.2rem;
    // width: 80%;
    // margin-left: 10%;
    margin-bottom: 1.2rem;
    .odd_box{
      display: flex;
      padding-bottom: 1.2rem;
      .left_box {
        width: 35%;
        display: flex;
        flex-flow: column;
        .title {
          margin-top: 1.6rem;
          min-height: 0.6rem;
          line-height: 0.6rem;
          font-size: 0.6rem;
          font-family: 'MicrosoftYaHei', 'MicrosoftYaHei-Bold';
          font-weight: 700;
          color: #333333;
           display: -webkit-box; /*值必须为-webkit-box或者-webkit-inline-box*/
          -webkit-box-orient: vertical; /*值必须为vertical*/
          -webkit-line-clamp: 1; /*值为数字，表示一共显示几行*/
          overflow: hidden;
          cursor: pointer;
        }
        .content {
          font-size: 16px;
          font-family: 'SimHei';
          text-align: left;
          color: #333333;
          line-height: 0.6rem;
          letter-spacing: 2px;
          display: -webkit-box; /*值必须为-webkit-box或者-webkit-inline-box*/
          -webkit-box-orient: vertical; /*值必须为vertical*/
          -webkit-line-clamp: 5; /*值为数字，表示一共显示几行*/
          overflow: hidden;
          height: 3rem;
          cursor: pointer;
        }
      }
      .right_box {
        flex: 1;
        position: relative;
        .image {
          width: calc(100% - 2rem);
          height: calc(100% - 2rem);
          margin-left: 1rem;
          margin-top: 1rem;
          border-radius: 12px;
          cursor: pointer;
        }
      }
    }
    .dub_box {
      display: flex;
      padding-bottom: 1.2rem;
      .left_box {
        width: 65%;
        position: relative;
        .left_img {
          position: absolute;
          bottom:0.2rem;
          left:0.2rem;
          width:3rem;
          height:3rem
        }
        .right_img {
          position: absolute;
          right:0.2rem;
          top:0.2rem;
          width:3rem;
          height:3rem
        }
        .image {
          width: calc(100% - 2rem);
          height: calc(100% - 2rem);
          margin-left: 1rem;
          margin-top: 1rem;
          border-radius: 12px;
          cursor: pointer;
        }
      }
      .right_box {
        flex: 1;
        display: flex;
        flex-flow: column;
        .title {
          margin-top: 1.6rem;
          min-height: 0.6rem;
          line-height: 0.6rem;
          font-size: 0.6rem;
          font-family: 'MicrosoftYaHei', 'MicrosoftYaHei-Bold';
          font-weight: 700;
          color: #333333;
          display: -webkit-box; /*值必须为-webkit-box或者-webkit-inline-box*/
          -webkit-box-orient: vertical; /*值必须为vertical*/
          -webkit-line-clamp: 1; /*值为数字，表示一共显示几行*/
          overflow: hidden;
          cursor: pointer;
        }
        .content {
          font-size: 16px;
          font-family: 'SimHei';
          text-align: left;
          color: #333333;
          line-height: 0.6rem;
          letter-spacing: 2px;
          display: -webkit-box; /*值必须为-webkit-box或者-webkit-inline-box*/
          -webkit-box-orient: vertical; /*值必须为vertical*/
          -webkit-line-clamp: 5; /*值为数字，表示一共显示几行*/
          overflow: hidden;
          height: 3rem;
          cursor: pointer;
        }
      }
    }
    .btn {
      margin-top: 1rem;
      padding: 0 0.5rem;
      height: 38px;
      line-height: 38px;
      font-size: 18px;
      font-family: 'MicrosoftYaHei';
      // text-align: center;
      color: #3bb573;
      border: 1px solid #3bb573;
      border-radius: 22px;
      display: inline-block;
      cursor: pointer;
    }
    .color_line {
      height: 0.3rem;
    }
  }
  .block {
    text-align: center;
  }
  .goTop {
    height: 14px;
    opacity: 1;
    font-size: 14px;
    font-family: 'MicrosoftYaHei';
    text-align: center;
    font-weight: 700;
    color: #333333;
    line-height: 14px;
  }
}
</style>
<style lang="scss">

@media screen and (max-width:600px) {
  .box .news .odd_box .left_box .title {
    font-size: 0.2rem !important;
    margin-top: 0.6rem !important;
  }
  .box .city{
    margin: 0 !important;
  }
  .box .news .odd_box .right_box .image {
    width: calc(100% - 1rem);
    height: calc(100% - 3rem);
    margin-left: 0.5rem;
  }
  .box .news .dub_box .left_box .image {
    width: calc(100% - 1rem);
    height: calc(100% - 3rem);
    margin-left: 0.5rem;
  }
  .left_img {
    right: 0.2rem;
    top: 0.6rem;
    width: 1rem;
    height: 1rem;
  }
  .right_img {
    right: 0.2rem;
    top: 0.6rem;
    width: 1rem;
    height: 1rem;
  }
  .btn {
    margin-top: 0;
  }
}
</style>
